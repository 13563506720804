.hero-wapper{
    color: white;
    position: relative;
    padding-bottom: 2rem;
    z-index: 1;
    /* background-color: white */
}
.hero-container{
    justify-content: space-around;
    align-items: flex-end;
}
/* left-side */

.search-bar{
    background-color: white;
    border-radius: 5px;
    border: 3px solid rgba(120, 120, 120, 0.374);
    padding: 0.5rem, 1rem;
    justify-content: space-between;
    width: 100%;
}
.search-bar>input{
    border: none;
    outline: none;
}

/* right side */
.image-container{
    width: 28rem;
    height: 30rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid  rgpa(255,255,255,0.12);
}
.image-container>img{
    width: 100%;
    height: 100%;
}
.hero-left{
    gap: 2rem;
    margin-left: 25px;

}
.hero-title{
    position: relative;
    z-index: 1;
}
.hero-title>h1{
    font-weight: 500;
    font-size: 3rem;
    line-height:4.5rem;
}
@media(max-width:640px) {
    .hero-container{
        margin-top: 2rem;
    }
    .hero-title>h1{
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .image-container{
        width: 90%;
        height: 25rem;
    }
    .hero-right{
        width: 100%;
    }
}