:root {
  --primary: #1f3e72;
  --secondary: rgba(255, 255, 255, 0.78);
  --black: #131110;
  --blue-gradient: linear-gradient(97.05deg, #4066ff 3.76%, #2949c6 100%);
  --orange-gradient: linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
  --blue: #4066ff;
  --lightBlue: #eeeeff;
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
}

*{
  margin: 0;
  pad: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
  

}
a{
  color: inherit;
  text-decoration: none;
}
.paddings{
  padding: 1.5rem;
}
.innerWidth{
  width: 100%;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexCenter{
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.button{
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white;
  background: var(--blue-gradient);
  border: none;
  border-radius: 4px;
  transition: all 300 ease-in;
}
.button:hover{
  cursor: pointer;
  transform: scale(1.1);
}
.flexColStart{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: flex-start;
}
.orange-cricle{
  height: 4rem;
  width: 4rem;
  background: var(--orange-gradient);
  border-radius: 100%;
  position: absolute;
  right: 8%;
  top:-23px;
  z-index: -1;
}

.primaryText {
  color: #1f3e72;
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText {
  color: rgb(140 139 139);
  font-size: 0.9rem;
}
.orangeText {
  color: orange;
  font-size: 1.5rem;
  font-weight: 600;
}


